import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  useGetEventsQuery, // get all events
  useAddEventMutation, // add an event
  useUpdateEventMutation, // update an event
  useUploadImageMutation, // upload image
} from "@services/api/apiSlice";

import {
  NameInput,
  DescriptionInput,
  IdInput,
  CategoryInput,
  TagsInput,
  IsAvailableInput,
  RestrictedCountInput,
  IsLimitInput,
} from "@fields/Fields";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

import RewardsPicker from "@pickers/rewardsPicker";

import { Event, defaultEvent } from "./Event";
import { PageLayout } from "@layout/page-layout";

import ImagePicker from "@pickers/imagePicker";

const EventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  // Note! We can provide eventId in either url params or using props for it
  // Current approach is using url params now
  const [isNew, setIsNew] = useState<boolean>(false);
  const navigate = useNavigate();

  const account = "gltest";

  const [addNewEvent, { isLoading }] = useAddEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const { data: events = [] } = useGetEventsQuery(account);
  const [event, setEvent] = useState<Event>({ ...defaultEvent, account });

  const [errors, setErrors] = useState<Partial<Record<keyof Event, string>>>(
    {}
  );

  // Image uploading
  const [file, setFile] = useState<File | null>(null);
  const [uploadImage] = useUploadImageMutation();

  useEffect(() => {
    console.log("in useEffect");
    if (eventId == "new") {
      setIsNew(true);
      setEvent({ ...defaultEvent, account });
    } else {
      const eventToEdit = events.find((event) => event.id === eventId);
      if (eventToEdit) {
        setIsNew(false);
        setEvent(eventToEdit);
      } else {
        // Note! This should never happen
        //console.error(`Event with id ${eventId} not found`);
        //navigate("/events");
      }
    }
  }, [events, eventId, navigate]);

  const updateField = <K extends keyof Event>(field: K, value: Event[K]) => {
    setEvent((prev) => ({ ...prev, [field]: value }));
    // Clear error when field is updated
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  console.log(isNew, JSON.stringify(event));

  // First the imahge needs to be saved. saveImage
  // returns the path to the stored image which needs
  // to be provided when then element is saved
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isNew) {
      try {
        const path = await saveImage(account, file, event.imgUrl);
        await addNewEvent({ ...event, imgUrl: path }).unwrap();
      } catch (err) {
        console.log("Failed to save event or image");
      }
      setIsNew(false);
    } else {
      // update event
      try {
        const path = await saveImage(account, file, event.imgUrl);
        await updateEvent({ ...event, imgUrl: path }).unwrap();
      } catch (err) {
        console.log("Failed to save event or image");
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Image uploading
  const handleImagePicked = (file: File) => {
    if (file) {
      setFile(file);
    }
  };

  const saveImage = async (
    account: string,
    file: File | null,
    imgUrl: string
  ): Promise<string> => {
    if (!file || !account) return imgUrl;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const result = await uploadImage({ formData, account }).unwrap();
      return result.path;
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
    // In case failing saved, we just return the old imgUrl
    return imgUrl;
  };

  return (
    <PageLayout>
      <div>
        <Link to={"/events"}>{"<-- Back"}</Link>
        <form className="mx-4 mr-24 p-4 bg-[#F3F7FF]">
          <Label className="text-2xl font-bold mb-4">
            {event ? event.name : "Create new event"}
          </Label>
          <div>
            <IsAvailableInput
              value={event.isAvailable}
              onChange={(value) => updateField("isAvailable", value)}
            />
          </div>
          <fieldset className="grid grid-cols-2 gap-4">
            <IdInput
              value={event.id}
              required
              onChange={(value) => updateField("id", value)}
              placeholder="Give your Event a unique ID"
              disabled={!isNew}
            />
            <NameInput
              value={event.name}
              required
              onChange={(value) => updateField("name", value)}
              placeholder="Give your Event a name"
            />
          </fieldset>

          <fieldset className="grid grid-cols-2 gap-4">
            <DescriptionInput
              value={event.description}
              onChange={(value) => updateField("description", value)}
              placeholder="You can also give your Event a description here"
            />
            <ImagePicker
              path={event?.imgUrl}
              id={event?.id}
              onImagePicked={handleImagePicked}
              size="small"
            />
          </fieldset>

          <fieldset className="grid grid-cols-4 gap-4">
            <CategoryInput
              value={event.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={event.tags}
              onChange={(value) => updateField("tags", value)}
            />
            <RestrictedCountInput
              value={event.countLimit}
              onChange={(value) => updateField("countLimit", value)}
              placeholder="Limit number of completions"
              disabled={!event.limitCount}
            />
            <IsLimitInput
              value={event.limitCount}
              onChange={(value) => updateField("limitCount", value)}
            />
          </fieldset>
          <hr />
          <fieldset>
            <RewardsPicker
              reward={event.reward}
              onChange={(value) => updateField("reward", value)}
            />
          </fieldset>
          <Button type="submit" onClick={handleSave}>
            Save
          </Button>
        </form>
      </div>
    </PageLayout>
  );
};

export default EventPage;
