import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LogoutButton } from "@buttons/logoutButton";

export const NavBarButtons: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="nav flex flex-col gap-2 m-4">
      <a href="/">Home</a>
      <a href="/accounts">Accounts</a>
      <a href="/events">Events</a>
      <a href="/levels">Levels</a>
      <hr />
      {isAuthenticated && (
        <LogoutButton />
      )}
    </div>
  );
};
