import React from "react";
import bwLogo from "@assets/gl_bw_logo.png";
import { ExpandableButtons } from "@buttons/expandableButtons";
import { ElementBase } from "@features/common/Common";


interface EntityPreviewProps {
  entityType: string;
  entity?: ElementBase;
}

export const EntityPreview: React.FC<EntityPreviewProps> = ({ entityType, entity }): React.ReactNode => {
  let content = <></>;
  const baseUrl = `/${entityType + '/' + (entity?.id || entity?.name)}`;

  content = entity ? (<>
    <div className="image">
      <img
        className="w-20 h-20 rounded-full"
        src={
          entity.imgUrl && entity.imgUrl !== ""
            ? entity.imgUrl
            : bwLogo
        }
        alt={entity.name}
      />
    </div>
    <div className="">
      <h4 className="font-extrabold text-l text-black">
        {entity.name}
      </h4>
      <p>{entity.description}</p>
    </div>
    <div className="ml-auto">
      <ExpandableButtons
        id={entity?.id || entity?.name}
        links={[
          { label: "Edit", url: `${baseUrl}` },
          {
            label: "Delete",
            url: `${baseUrl}/delete`,
          },
          {
            label: "Duplicate",
            url: `${baseUrl}/clone`,
          },
        ]}/>
    </div>
  </>) : (<p>No data available</p>);

  return content;
};