import React from "react";
import { NavBar } from "@navigation/navbar";
import Logo from "@elements/logo";

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
    <header className="bg-[#F5F5F5] p-4 flex gap-2 justify-items items-center">
      <Logo src={null} alt="GameLayer"/>
      <span className="text-[#212121] text-2xl">GameLayer</span>
    </header>
    <div className="container flex mb-5">
      <aside className="min-w-32">
        <NavBar />
      </aside>
      <div className="min-w-full">
        {children}
      </div>
    </div>
    </>
  );
};
