import React from 'react';

type ActionButtonProps = {
  onClick?: () => void;
  label?: string;
  to?: string;
  children: React.ReactNode;
};

const ActionButton: React.FC<ActionButtonProps> = ({ label, onClick, to, children }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (to) {
    return (
      <a
        href={to}
        onClick={handleClick}
        title={label}
        className="rounded-full bg-blue-500 text-white py-2 px-4">
      {children}
      </a>
    );
  }

  return (
    <button
      onClick={handleClick}
      title={label}
      className="rounded-full bg-blue-500 text-white py-2 px-4">
      { label }
      {children}
    </button>
  );
};

export default ActionButton;
