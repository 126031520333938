import React from 'react';
import blueLogo from '@assets/blue_logo.png';

interface LogoProps {
  src: string | null;
  alt: string;
}

const Logo: React.FC<LogoProps> = ({ src, alt }) => {
  return <img className='max-w-6 max-h-6' src={src ? src : blueLogo } alt={alt} />;
};

export default Logo;