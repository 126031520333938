import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "@layout/page-loader";
import { AuthenticationGuard } from "@layout/authentication-guard";
import { CallbackPage } from "@pages/callback-page";
import { HomePage } from "@pages/home-page";
import { NotFoundPage } from "@pages/not-found-page";
import { ProfilePage } from "@pages/profile-page";
import { ProtectedPage } from "@pages/protected-page";
import { PublicPage } from "@pages/public-page";
import AccountsPage from "@features/accounts/accountsPage";
import EventsPage from "@features/events/eventsPage";
import EventPage from "@features/events/eventPage";
import { setAuthToken } from "@services/api/apiSlice";
import LevelsPage from "@features/levels/levelsPage";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setAuthToken(() => getAccessTokenSilently());
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="page-layout flex justify-center items-center">
        <PageLoader />
      </div>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route
        path="/accounts"
        element={<AuthenticationGuard component={AccountsPage} />}
      />
      <Route
        path="/levels"
        element={<AuthenticationGuard component={LevelsPage} />}
      />
      <Route
        path="/events"
        element={<AuthenticationGuard component={EventsPage} />}
      />
      <Route
        path="/events/:eventId"
        element={<AuthenticationGuard component={EventPage} />}
      />
      <Route path="/public" element={<PublicPage />} />
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
