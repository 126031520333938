import React, { useState, useEffect, ChangeEvent, useRef } from "react";

const DEFAULT_IMAGE = import.meta.env.VITE_APP_DEFAULT_IMAGE;

const imageUrl = (file: File) => {
  return file && file instanceof File
    ? URL.createObjectURL(file)
    : DEFAULT_IMAGE;
};

const smallImageSize = 72;
const normalImageSize = 122;

interface ImagePickerProps {
  path: string;
  onImagePicked: (file: File) => void;
  id: string;
  size: string;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  path,
  onImagePicked,
  id,
  size,
}) => {
  const [imageSource, setImageSource] = useState<File | null>(null);
  const [imgPath, setImgPath] = useState(DEFAULT_IMAGE);
  const hiddenFileInput = useRef(null);

  const inputId = `contained-button-file-${id}`;
  const isSmallImage = size === "small";
  const imageSize = isSmallImage ? smallImageSize : normalImageSize;

  useEffect(() => {
    if (!imageSource) return;
    onImagePicked(imageSource);
    return () => {
      //cleanup;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgPath]);

  useEffect(() => {
    if (path != null && path !== "") {
      setImgPath(path);
    }
  }, [path]);

  const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageSource(file);
      setImgPath(imageUrl(file));
    }
  };

  return (
    <fieldset className="grid grid-cols-2 gap-4">
      <div>
        <div></div>
        <input
          accept="image/*"
          id={inputId}
          multiple={false}
          type="file"
          ref={hiddenFileInput}
          onChange={handleCapture}
          style={{ display: "none" }}
        />
        <div className="">
          <label htmlFor={inputId}>
            <div className="w-[200px] h-[200px] relative overflow-hidden">
              <img
                src={imgPath}
                height={imageSize}
                width={imageSize}
                alt=""
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          </label>
        </div>
      </div>
    </fieldset>
  );
};

export default ImagePicker;
