import React from "react";

import { Rewards } from "../../features/common/Common";
import { PointsInput, CreditsInput } from "../fields/Fields";

interface RewardPickerProps {
  reward: Rewards;
  onChange: (value: Rewards) => void;
}
const RewardsPicker: React.FC<RewardPickerProps> = ({ reward, onChange }) => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Rewards</h1>
      <fieldset className="grid grid-cols-2 gap-4">
        <PointsInput
          value={reward.points}
          onChange={(points) => onChange({ ...reward, points })}
        />
        <CreditsInput
          value={reward.credits}
          onChange={(credits) => onChange({ ...reward, credits })}
        />
      </fieldset>
    </div>
  );
};

export default RewardsPicker;
