import React from "react";
import { NavBarButtons } from "./navbarButtons";

export const NavBar: React.FC = () => {
  return (
    <div className="top-24 mx-2 border sticky rounded-lg">
      <nav>
        <NavBarButtons />
      </nav>
    </div>
  );
};
