import React, { ChangeEvent } from "react";

import { Input } from "@components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

// Common interface for input props
interface StringInputProps {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
}

interface InputWrapperProps {
  name: string;
  label: string;
  required?: boolean;
  error?: string;
  children: React.ReactNode;
}

// InputWrapper component
// "block text-sm font-medium text-gray-700"
const InputWrapper: React.FC<InputWrapperProps> = ({
  name,
  label,
  required = false,
  error,
  children,
}) => (
  <div>
    <Label htmlFor={name}>
      {label}
      {required && <span style={{ color: "red" }}> *</span>}:
    </Label>
    {children}
    {error && <div style={{ color: "red", fontSize: "0.8em" }}>{error}</div>}
  </div>
);

// IdInput component
export const IdInput: React.FC<StringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  placeholder,
  disabled = false,
}) => (
  <InputWrapper name="id" label="ID" required={required} error={error}>
    <Input
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      type="text"
      id="id"
      name="id"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  </InputWrapper>
);

// NameInput component
export const NameInput: React.FC<StringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  placeholder,
}) => (
  <InputWrapper name="name" label="Name" required={required} error={error}>
    <Input
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      type="text"
      id="name"
      name="name"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
    />
  </InputWrapper>
);

// DescriptionInput component
// className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
export const DescriptionInput: React.FC<StringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  placeholder,
}) => (
  <InputWrapper
    name="description"
    label="Description"
    required={required}
    error={error}
  >
    <Textarea
      className="w-full h-full min-h-[100px] p-2 border-0 focus:ring-0 focus:outline-none resize-y"
      id="description"
      name="description"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
    />
  </InputWrapper>
);

// CategoryInput component
export const CategoryInput: React.FC<StringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  placeholder = "Add category",
}) => (
  <InputWrapper
    name="category"
    label="Category"
    required={required}
    error={error}
  >
    <Input
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      type="text"
      id="category"
      name="category"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
    />
  </InputWrapper>
);

// TagsInput component
interface TagsStringInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
}

export const TagsInput: React.FC<TagsStringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  placeholder = "Add tags separated by commas",
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tags = e.target.value.split(",").map((tag) => tag.trim());
    onChange(tags);
  };

  return (
    <InputWrapper name="tags" label="Tags" required={required} error={error}>
      <Input
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        type="text"
        id="tags"
        name="tags"
        value={Array.isArray(value) ? value.join(",") : value}
        onChange={handleChange}
        required={required}
        placeholder={placeholder}
      />
    </InputWrapper>
  );
};

interface NumericInputProps {
  value: number;
  onChange: (value: number) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
}

// Base NumericInput component (internal use only)
interface BaseNumericInputProps extends NumericInputProps {
  name: string;
  label: string;
  min?: number;
  max?: number;
}

const BaseNumericInput: React.FC<BaseNumericInputProps> = ({
  value,
  onChange,
  name,
  label,
  error,
  placeholder,
  disabled = false,
  min,
  max,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    onChange(newValue);
  };

  return (
    <InputWrapper name={name} label={label} error={error}>
      <Input
        type="number"
        id={name}
        name={name}
        value={value || ""}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
      />
    </InputWrapper>
  );
};

// RestrictedCountInput component
export const RestrictedCountInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  error,
  disabled = false,
  placeholder = "Enter restricted count (non-negative)",
}) => (
  <BaseNumericInput
    name="restrictedCount"
    label="Restricted Count"
    value={value}
    onChange={onChange}
    error={error}
    placeholder={placeholder}
    disabled={disabled}
    min={0}
  />
);

// PointsInput component
export const PointsInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="points"
    label="Points"
    value={value}
    onChange={onChange}
    error={error}
    placeholder="Enter points for this event"
    disabled={disabled}
    min={0}
  />
);

// CreditsInput component
export const CreditsInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="credits"
    label="Credits"
    value={value}
    onChange={onChange}
    error={error}
    placeholder="Enter credits for this event"
    disabled={disabled}
    min={0}
  />
);

// Boolean type of input fields
interface BooleanInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  error?: string;
  label: string;
  name: string;
}

const BooleanInput: React.FC<BooleanInputProps> = ({
  value,
  onChange,
  name,
}) => (
  <div className="flex items-center space-x-2">
    <Switch
      id={name}
      checked={value}
      onCheckedChange={(value) => onChange(value)}
    />
    <Label htmlFor="toggle-switch">{name}</Label>
  </div>
);

export const IsAvailableInput: React.FC<
  Omit<BooleanInputProps, "label" | "name">
> = (props) => (
  <BooleanInput {...props} label="Is Event Available" name="isAvailable" />
);

export const IsLimitInput: React.FC<
  Omit<BooleanInputProps, "label" | "name">
> = (props) => (
  <BooleanInput {...props} label="Is Limit Enabled" name="isLimit" />
);
